import NextLeasePageModel from 'Models/Pages/NextLeasePage/NextLeasePageModel.interface';
import useCurrentPage from 'Shared/Hooks/useCurrentPage';

function NextLeasePage() {
  useCurrentPage<NextLeasePageModel>();

  return (
    <>
      <div id="nextlease" data-uid="635b0617-66eb-4f5a-ac8b-2594f48e19bb"></div>
    </>
  );
}

export default NextLeasePage;
